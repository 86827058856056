import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import WorksText from "./WorksText";
import Masonry from "react-masonry-css";

const WORKS = gql`
  query WorksQuery {
    works(first: 10000) {
      edges {
        node {
          title
          worksData {
            title
            shortDescription
            description
            credits
            services
            featuredImage {
              sourceUrl
            }
            secondaryImage {
              sourceUrl
            }
            aspectRatio
            imagesGrid {
              images {
                sourceUrl
              }
              aspectRatio
            }
          }
        }
      }
    }
  }
`;

const ABOUT = gql`
  query AboutQuery {
    about {
      edges {
        node {
          aboutData {
            testo
          }
        }
      }
    }
  }
`;

const WorkArchive = ({ limitToShow }) => {
  const { loading, error, data } = useQuery(WORKS);
  const {
    loading: loadingAbout,
    error: errorAbout,
    data: dataAbout,
  } = useQuery(ABOUT);
  const [filter, setFilter] = useState("");
  const [existingSubtitles, setExistingSubtitles] = useState([]);

  if (loading) return <LoadingSpinner />;
  if (loadingAbout) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;
  if (errorAbout) return <ErrorMessage error={errorAbout} />;

  const filteredData = getFilteredData(data, filter);
  const subtitles = getSubtitles(data);

  const displayedData = filteredData;

  return (
    <>
      <div className="mb-20 md:mb-40 animate-slideInBottom">
        <SubtitleButtons
          subtitles={subtitles}
          setFilter={setFilter}
          currentFilter={filter}
        />
      </div>

      <div>
        <WorksGrid data={displayedData} />
        {/* inserisci un div di testo a tutto schermo come descrizione breve di Silvia */}
        <div className="flex flex-col items-center justify-center mt-36 mb-36">
          <div className="flex flex-col items-center justify-center md:w-4/5">
            <h2 className="mb-6 text-28 font-montserrat md:w-full">
              {dataAbout.about.edges[0].node.aboutData.testo}
            </h2>
          </div>
        </div>

        <div>
          <button
            className={
              "flex items-center justify-center mx-auto md:text-28 text-22 mt-36 mb-36 md:py-10 py-5 md:px-32 px-16 md:font-light  font-montserrat  text-white uppercase transition duration-300 rounded-full  bg-bs-purple hover:bg-black"
            }
            //send email to silvia.bellutti@gmail.com onclick
            onClick={() => {
              window.location.href = "mailto:silvia.bellutti@gmail.com	";
            }}
          >
            Contattami
          </button>
        </div>
      </div>
    </>
  );
};

const LoadingSpinner = () => (
  <div className="flex items-center justify-center ">
    <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
  </div>
);

const ErrorMessage = ({ error }) => <p>Error: {error.message}</p>;

const getFilteredData = (data, filter) =>
  filter
    ? data.works.edges.filter((edge) =>
        edge.node.worksData.services.includes(filter)
      )
    : data.works.edges;

const getSubtitles = (data) => {
  const allServices = data.works.edges.flatMap((edge) => {
    if (typeof edge.node.worksData.services === "string") {
      return edge.node.worksData.services.split(", ");
    } else if (Array.isArray(edge.node.worksData.services)) {
      return edge.node.worksData.services;
    } else {
      return [];
    }
  });
  return [...new Set(allServices)];
};

const SubtitleButtons = ({ subtitles, setFilter, currentFilter }) => {
  const [selectedSubtitle, setSelectedSubtitle] = useState("");

  const handleSubtitleClick = (subtitle) => {
    if (selectedSubtitle === subtitle) {
      setSelectedSubtitle("");
      setFilter("");
    } else {
      setSelectedSubtitle(subtitle);
      setFilter(subtitle);
    }
  };

  return (
    <div className="flex flex-wrap justify-center gap-4 mb-4 space-x-4 ">
      {/* Bottone "All" per resettare il filtro (visibile solo da mobile) */}
      <button
        className={`md:hidden flex items-center justify-center md:px-10 px-5 md:py-1.5 py-2.5 md:font-light font-normal font-montserrat  text-white uppercase transition duration-300 rounded-full text-13 md:text-22' ${
          currentFilter === "" ? "bg-black" : "bg-bs-purple hover:bg-black"
        }`}
        onClick={() => {
          setSelectedSubtitle("");
          setFilter("");
        }}
      >
        All
      </button>
      {subtitles.map((subtitle) => (
        <button
          key={subtitle}
          className={`flex items-center justify-center   md:px-10 px-5 md:py-1.5 py-2.5 md:font-light font-normal font-montserrat  text-white uppercase transition duration-300 rounded-full text-13 md:text-22 ' ${
            selectedSubtitle === subtitle
              ? "bg-black"
              : "bg-bs-purple hover:bg-black"
          }`}
          onClick={() => handleSubtitleClick(subtitle)}
        >
          {subtitle}
        </button>
      ))}
    </div>
  );
};

const WorksGrid = ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1024: 2,
  };

  const getAspectRatioClass = (aspectRatioString) => {
    const aspectRatioMap = {
      "4:5": "aspect-w-4 aspect-h-5",
      "1:1": "aspect-w-1 aspect-h-1",
      "3:2": "aspect-w-3 aspect-h-2",
      "16:9": "aspect-w-16 aspect-h-9",
    };

    return aspectRatioMap[aspectRatioString] || "";
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid-column"
    >
      {data.map((edge, index) => {
        const ImageClass = getAspectRatioClass(edge.node.worksData.aspectRatio);

        return (
          <div key={index} className="masonry-item animate-slideInTop">
            <Link
              to={`/works/${edge.node.worksData.title}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div
                className={`${ImageClass} w-full h-auto mb-2 relative group`}
              >
                {/* Featured Image */}
                <img
                  src={edge.node.worksData.featuredImage.sourceUrl}
                  alt={edge.node.title}
                  className={`absolute top-0 left-0 z-10 object-cover object-center w-full h-full transition-opacity duration-300 ${
                    edge.node.worksData.secondaryImage?.sourceUrl
                      ? "group-hover:opacity-0"
                      : ""
                  }`}
                />
                {/* Secondary Image */}
                {edge.node.worksData.secondaryImage?.sourceUrl && (
                  <img
                    src={edge.node.worksData.secondaryImage.sourceUrl}
                    alt={`Secondary for ${edge.node.title}`}
                    className="absolute top-0 left-0 object-cover object-center w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                  />
                )}
              </div>
              <div className="flex items-center justify-between ">
                <span className="mr-2 text-13 font-montserrat">
                  {edge.node.title}
                </span>
              </div>
              <p className="mb-5 text-gray-400 text-13 font-montserrat">
                {edge.node.worksData.shortDescription}
              </p>
            </Link>
          </div>
        );
      })}
    </Masonry>
  );
};

export default WorkArchive;
