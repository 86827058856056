import React from "react";
import WorkSlider from "../components/WorkSlider";
import TextSlider from "../components/TextSlider";
import SeeWorkSlider from "../components/SeeWorkSlider";
import WorkArchive from "../components/WorkArchive";
import HomeText from "../components/HomeText";
import { useState, useEffect } from "react";
import Header from "../components/Header";

function Homepage() {
  const [filter, setFilter] = useState("");
  return (
    <div className="p-4 py-0 mx-auto overflow-x-hidden ">
      <div className="flex mt-8 justify-self-start"></div>

      <div>
        <WorkArchive />
      </div>
    </div>
  );
}

export default Homepage;
