import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import mail from "../images/mail.png";
import instagram from "../images/instagram.png";
import linkedin from "../images/linkedin.png";
import logo from "../images/logo.png";

const LINKS_QUERY = gql`
  query LinksQuery {
    links {
      edges {
        node {
          linksData {
            instagram
            email
            linkedin
          }
        }
      }
    }
  }
`;

const FOOTER_QUERY = gql`
  query FooterQuery {
    footerText {
      edges {
        node {
          footertextData {
            row1
            title
            row2
          }
        }
      }
    }
  }
`;

function Footer() {
  const { data, loading, error } = useQuery(LINKS_QUERY);
  const {
    data: footerData,
    loading: footerLoading,
    error: footerError,
  } = useQuery(FOOTER_QUERY);

  const location = useLocation();

  const handleLinkClick = (e) => {
    if (location.pathname === "/") {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  if (loading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );
  if (error) return <p>Error: {error?.message}</p>;

  if (footerLoading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );
  if (footerError) return <p>Error: {footerError?.message}</p>;

  const dataLinks = data.links.edges[0].node.linksData;
  const dataFooter = footerData.footerText.edges[0].node.footertextData;

  return (
    <footer className="text-white bg-black ">
      <div className="items-center justify-center hidden grid-cols-2 gap-8 p-4 md:grid-cols-2 md:grid ">
        {/* Colonna di sinistra */}

        <div className="mt-8">
          <div className="mb-4">
            <span className="text-left text-22 font-montserrat">Contact</span>
            <div className="flex gap-8 mt-4">
              <Link to={dataLinks.instagram}>
                <img src={instagram} alt="Instagram" className="w-16 h-16" />
              </Link>
              <Link to={dataLinks.email}>
                <img src={mail} alt="email" className="w-16 h-16" />
              </Link>
              <Link to={dataLinks.linkedin}>
                <img src={linkedin} alt="LinkedIn" className="w-16 h-16" />
              </Link>
            </div>
          </div>
        </div>

        {/* Colonna dei contatti */}

        <div className="justify-end mt-8 md:w-full">
          <Link to="/" onClick={handleLinkClick}>
            <div className="grid items-center grid-cols-3">
              <div className="text-3xl font-light text-center text-white uppercase transform translate-x-1/2 opacity-0 font-montserrat md:text-right md:text-4xl animate-slideInLeft">
                <span>visual</span>
              </div>
              <div className="text-center">
                <img
                  src={logo}
                  alt="Logo"
                  className="mx-auto md:w-3/5 invert-1"
                />
              </div>
              <div className="text-3xl font-light text-center text-white uppercase transform -translate-x-1/2 opacity-0 font-montserrat md:text-left md:text-4xl animate-slideInRight">
                <span>Designer</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="grid-cols-2 gap-8 p-4 md:grid-cols-2 md:hidden">
        {/* Colonna di sinistra */}

        <div className="mt-8 ">
          <div className="mb-4">
            <span className="text-left text-22 font-montserrat">Contact</span>
            <div className="flex justify-around mt-4">
              <Link to={dataLinks.instagram}>
                <img src={instagram} alt="Instagram" className="w-16 h-16" />
              </Link>
              <Link to={dataLinks.email}>
                <img src={mail} alt="Email" className="w-16 h-16" />
              </Link>
              <Link to={dataLinks.linkedin}>
                <img src={linkedin} alt="LinkedIn" className="w-16 h-16" />
              </Link>
            </div>
          </div>
        </div>

        {/* Colonna dei contatti */}

        <div>
          <Link to="/" onClick={handleLinkClick}>
            <div className="hidden md:block">
              <div className="grid items-center justify-center grid-cols-2 mx-auto mt-10">
                <div className="text-center">
                  <img
                    src={logo}
                    alt="Logo"
                    className="mx-auto md:w-2/4 invert-1"
                  />
                </div>
                <div className="text-2xl font-light text-center text-white uppercase transform -translate-x-1/2 opacity-0 font-montserrat md:text-left md:text-7xl animate-slideInRight">
                  <span>visual</span>
                  <span>Designer</span>
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <div className="flex-col items-center justify-center grid-cols-3 mx-auto mt-10">
                <div className="text-center">
                  <img
                    src={logo}
                    alt="Logo"
                    className="w-3/4 mx-auto invert-1"
                  />
                </div>
                <div className="text-2xl font-light text-center text-white uppercase transform translate-x-1/2 opacity-0 font-montserrat md:text-right md:text-7xl animate-slideInLeft">
                  <span>visual</span>
                </div>

                <div className="text-2xl font-light text-center text-white uppercase transform -translate-x-1/2 opacity-0 font-montserrat md:text-left md:text-7xl animate-slideInRight">
                  <span>Designer</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="w-full mt-5 mb-5 border-t border-white">
        <p className="hidden py-4 text-center text-13 font-favorit md:block">
          © 2023 - Silvia Bellutti Visual Designer All rights reserved
        </p>
        <p className="py-4 text-center text-13 font-favorit md:hidden">
          © 2023 - Silvia Bellutti Visual Designer All rights reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
