// components/Header.js

import React from "react";
import Navbar from "./Navbar";
import HeadTitle from "./HeadTitle";

function Header({ setFilter, currentFilter }) {
  return (
    <header>
      <HeadTitle />
    </header>
  );
}

export default Header;
