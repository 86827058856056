import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation]);
const GET_WORK_DETAIL = gql`
  query NewQuery($id: ID = "") {
    work(idType: URI, id: $id) {
      title
      worksData {
        title
        shortDescription
        location
        description
        credits
        services
        featuredImage {
          sourceUrl
        }
        imagesGrid {
          images {
            sourceUrl
            mediaType
            mediaItemUrl
          }
          aspectRatio
        }
      }
    }
  }
`;

const GET_WORKS = gql`
  query NewQuery {
    works {
      edges {
        node {
          worksData {
            title
            location
            featuredImage {
              sourceUrl
            }
            imagesGrid {
              images {
                sourceUrl
                mediaType
              }
              aspectRatio
            }
          }
        }
      }
    }
  }
`;

const WorkDetail = () => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_WORK_DETAIL, {
    variables: { id },
  });
  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 1000); // Puoi regolare la durata del preloader
  }, []);

  const {
    loading: worksLoading,
    error: worksError,
    data: worksData,
  } = useQuery(GET_WORKS);

  if (!isPageLoaded) {
    // Mostra il preloader fino a quando la pagina non è stata caricata completamente
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600">Loading...</p>
      </div>
    );
  }

  if (loading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );
  if (error) return <p>Error: {error.message}</p>;

  // Verifica se data e data.work esistono
  if (!data || !data.work || !data.work.worksData) {
    return <p>No work details available</p>;
  }

  if (worksLoading)
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600">Loading...</p>
      </div>
    );

  if (worksError) return <p>Error: {worksError.message}</p>;

  // Verifica se data e data.work esistono
  if (!worksData || !worksData.works || !worksData.works.edges) {
    return <p>No work details available</p>;
  }

  const workDetails = data.work.worksData;

  const workList = worksData.works.edges.map((edge) => {
    return {
      title: edge.node.worksData.title,
      location: edge.node.worksData.location,
      featuredImage: edge.node.worksData.featuredImage.sourceUrl,
      imagesGrid: edge.node.worksData.imagesGrid,
    };
  });

  const getAspectRatioRows = (aspectRatioString) => {
    const aspectRatioMap = {
      "1:1 (4)": " grid grid-cols-2 md:gap-4 gap-2 md:mb-4 mb-2 md:grid-cols-4",
      "3:2 (1)": " md:gap-4 gap-2 md:mb-4 mb-2",
      "3:2 (2)": "grid grid-cols-2 md:gap-4 gap-2 md:mb-4 mb-2",
      "4:5 (2)": "grid grid-cols-2 md:gap-4 gap-2 md:mb-4 mb-2 md:grid-cols-2",
      "4:5 (4)": " grid grid-cols-2 md:gap-4 gap-2 md:mb-4 mb-2 md:grid-cols-4",
      "16:9 (1)": "md:gap-4 gap-2 md:mb-4 mb-2 ",
      "16:9 (2)":
        " grid grid-cols-2 md:gap-4 gap-2 md:mb-4 mb-2 md:grid-cols-2",
    };

    return aspectRatioMap[aspectRatioString] || "";
  };

  const getAspectRatioClass = (aspectRatioString) => {
    const aspectRatioMap = {
      "1:1 (4)": "aspect-w-1 aspect-h-1",
      "3:2 (1)": "aspect-w-3 aspect-h-2",
      "3:2 (2)": "aspect-w-3 aspect-h-2",
      "4:5 (2)": "aspect-w-4 aspect-h-5",
      "4:5 (4)": "aspect-w-4 aspect-h-5",
      "16:9 (1)": "aspect-w-16 aspect-h-9",
      "16:9 (2)": "aspect-w-16 aspect-h-9",
    };

    return aspectRatioMap[aspectRatioString] || "";
  };

  return (
    <div className="mx-auto pt-14 ">
      <div className="mx-5 ">
        <div className="flex items-start mb-10 gap-9 md:flex ">
          {/* Colonna di Sinistra */}
          <div className="hidden text-xs md:block md:w-1/4 ">
            {(Array.isArray(data.work.worksData.credits) &&
              data.work.worksData.credits.length > 0) ||
            (typeof data.work.worksData.credits === "string" &&
              data.work.worksData.credits.trim()) ? (
              <div className="mb-2 text-sm font-montserrat text-22">
                {Array.isArray(data.work.worksData.credits)
                  ? data.work.worksData.credits.map((credit, index) => {
                      const parts = credit.split(":");
                      if (parts.length !== 2) return null;
                      return (
                        <div key={index} className="mb-5">
                          <div>{parts[0].trim()}:</div>
                          <div>{parts[1].trim()}</div>
                        </div>
                      );
                    })
                  : data.work.worksData.credits
                      .split(";")
                      .map((credit, index) => {
                        const parts = credit.split(":");
                        if (parts.length !== 2) return null;
                        return (
                          <div key={index} className="mb-3">
                            <div>{parts[0].trim()}:</div>
                            <div>{parts[1].trim()}</div>
                          </div>
                        );
                      })}
              </div>
            ) : null}

            {(Array.isArray(data.work.worksData.services) &&
              data.work.worksData.services.length > 0) ||
            (typeof data.work.worksData.services === "string" &&
              data.work.worksData.services.trim()) ? (
              <div className="mb-2 text-sm font-montserrat text-22 ">
                <span className="text-sm text-22">Services:</span>
                <div>
                  {Array.isArray(data.work.worksData.services)
                    ? data.work.worksData.services.map((service, index) => (
                        <div key={index}>{service}</div>
                      ))
                    : data.work.worksData.services
                        .split(",")
                        .map((service, index) => (
                          <div key={index}>{service.trim()}</div>
                        ))}
                </div>
              </div>
            ) : null}
          </div>

          {/* Colonna di Destra */}
          <div
            className="text-22 md:text-28 md:w-3/4"
            style={{ lineHeight: "1.1" }}
          >
            <p className="mb-6 pr-9 font-montserrat text-22 md:text-28">
              {data.work.worksData.shortDescription}
            </p>
            <div
              className={
                isDescriptionExpanded
                  ? "relative"
                  : "relative max-h-0 md:max-h-full overflow-hidden"
              }
            >
              <p className="text-left pr-9 font-montserrat text-22 md:text-28">
                {data.work.worksData.description}
              </p>

              {!isDescriptionExpanded && (
                <div className="absolute bottom-0 w-full h-10"></div>
              )}
            </div>
            <button
              onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
              className="mt-1 text-black text-22 md:text-28 md:hidden font-montserrat"
            >
              {isDescriptionExpanded ? "Read Less ↑" : "Read More ↓"}
            </button>
          </div>
        </div>

        <div>
          {workDetails.imagesGrid.map((element, index) => {
            if (element.images.length === 0) return null;

            const ImagesRows = getAspectRatioRows(element.aspectRatio);
            const ImageClass = getAspectRatioClass(element.aspectRatio);

            return (
              <div key={index} className={`${ImagesRows}`}>
                {element.images.map((image, imgIndex) => (
                  <div key={imgIndex} className={`${ImageClass}`}>
                    {image.mediaType === "image" ? (
                      <img
                        className="aspect-content"
                        src={image.sourceUrl}
                        alt={`${element.aspectRatio} - ${index}-${imgIndex}`}
                      />
                    ) : (
                      image.mediaType === "file" && (
                        <video className="aspect-content" autoPlay loop>
                          <source src={image.mediaItemUrl} type="video/mp4" />
                        </video>
                      )
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </div>

        {/* Colonna di Sinistra visibile da mobile */}
        <div className="block mt-10 mb-10 text-xs md:hidden md:w-1/4 ">
          {(Array.isArray(data.work.worksData.credits) &&
            data.work.worksData.credits.length > 0) ||
          (typeof data.work.worksData.credits === "string" &&
            data.work.worksData.credits.trim()) ? (
            <div className="mb-2 font-montserrat text-13">
              {Array.isArray(data.work.worksData.credits)
                ? data.work.worksData.credits.map((credit, index) => {
                    const parts = credit.split(":");
                    if (parts.length !== 2) return null;
                    return (
                      <div key={index} className="mb-5">
                        <div>{parts[0].trim()}:</div>
                        <div>{parts[1].trim()}</div>
                      </div>
                    );
                  })
                : data.work.worksData.credits
                    .split(";")
                    .map((credit, index) => {
                      const parts = credit.split(":");
                      if (parts.length !== 2) return null;
                      return (
                        <div key={index} className="mb-5">
                          <div>{parts[0].trim()}:</div>
                          <div>{parts[1].trim()}</div>
                        </div>
                      );
                    })}
            </div>
          ) : null}

          {(Array.isArray(data.work.worksData.services) &&
            data.work.worksData.services.length > 0) ||
          (typeof data.work.worksData.services === "string" &&
            data.work.worksData.services.trim()) ? (
            <div className="mb-2 font-montserrat text-13">
              <span>Servizi:</span>
              <div>
                {Array.isArray(data.work.worksData.services)
                  ? data.work.worksData.services.map((service, index) => (
                      <div key={index}>{service}</div>
                    ))
                  : data.work.worksData.services
                      .split(",")
                      .map((service, index) => (
                        <div key={index}>{service.trim()}</div>
                      ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <button
          className={
            "flex items-center justify-center mx-auto md:text-28 text-22 mt-36 md:py-10 py-5 md:px-32 px-16 md:font-light  font-montserrat  text-white uppercase transition duration-300 rounded-full  bg-bs-purple hover:bg-black"
          }
          //send email to silvia.bellutti@gmail.com onclick
          onClick={() => {
            window.location.href = "mailto:silvia.bellutti@gmail.com	";
          }}
        >
          Contattami
        </button>
      </div>

      <div className="py-6 mb-0 mt-36 bg-fab-gray">
        <p className="px-4 mx-auto mb-4 text-28 font-montserrat">
          Related Projects
        </p>
        <div className="px-4 pb-16 mx-auto ">
          {workList && (
            <Swiper
              modules={[Navigation, Autoplay]}
              spaceBetween={10}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                // quando la larghezza dello schermo è >= 640px
                640: {
                  slidesPerView: 2,
                },
                // quando la larghezza dello schermo è >= 1024px
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {workList.map((work, index) => (
                <SwiperSlide key={work.title}>
                  <Link
                    to={`/works/${work.title}`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div className="relative group aspect-w-1 aspect-h-1">
                      <img
                        src={work.featuredImage}
                        alt={work.title}
                        className="object-cover transition-opacity duration-200 aspect-content group-hover:opacity-75"
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkDetail;
