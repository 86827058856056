import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function Navbar() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <nav
      className={` w-full z-50 flex justify-center  px-4 py-4  ${
        loaded ? "animate-fade-in-down" : ""
      }`}
    >
      {/* Bottoni a destra */}
      <div className="flex items-center justify-center space-x-4 md:space-x-8 ">
        <Link
          to="/works"
          className="flex items-center justify-center md:px-10 px-2  md:py-1.5 py-1 md:font-light font-normal font-montserrat  text-white uppercase transition duration-300 rounded-full text-13 md:text-22 bg-bs-purple"
          onClick={() => window.scrollTo(0, 0)}
        >
          Branding
        </Link>
        <Link
          to="/about"
          className="flex items-center justify-center md:px-10 px-2  md:py-1.5 py-1 md:font-light font-normal font-montserrat  text-white uppercase transition duration-300 rounded-full text-13 md:text-22 bg-bs-purple"
          onClick={() => window.scrollTo(0, 0)}
        >
          Packaging
        </Link>
        <Link
          to="/about"
          className="flex items-center justify-center md:px-10 px-2  md:py-1.5 py-1 md:font-light font-normal font-montserrat  text-white uppercase transition duration-300 rounded-full text-13 md:text-22 bg-bs-purple"
          onClick={() => window.scrollTo(0, 0)}
        >
          uxdesign
        </Link>
        <Link
          to="/about"
          className="md:flex hidden items-center justify-center md:px-10 px-2  md:py-1.5 py-1 font-light font-montserrat  text-white uppercase transition duration-300 rounded-full text-13 md:text-22 bg-bs-purple"
          onClick={() => window.scrollTo(0, 0)}
        >
          Social&newsletter
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
