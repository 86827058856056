import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const HeadTitle = () => {
  return (
    <Link to="/">
      <div className="hidden md:block">
        <div className="grid items-center grid-cols-3 ">
          <div className="text-3xl font-light text-center uppercase transform translate-x-1/2 opacity-0 font-montserrat md:text-right md:text-7xl text-bs-purple animate-slideInLeft">
            <span>visual</span>
          </div>
          <div className="text-center">
            <img src={logo} alt="Logo" className="mx-auto md:w-2/4" />
          </div>
          <div className="text-3xl font-light text-center uppercase transform -translate-x-1/2 opacity-0 font-montserrat md:text-left md:text-7xl text-bs-purple animate-slideInRight">
            <span>Designer</span>
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <div className="flex-col items-center grid-cols-3 ">
          <div className="text-center animate-slideInBottom">
            <img src={logo} alt="Logo" className="w-3/4 mx-auto" />
          </div>
          <div className="text-4xl font-light text-center uppercase transform translate-x-1/2 opacity-0 font-montserrat text-bs-purple animate-slideInLeft">
            <span>visual</span>
          </div>
          <div className="text-4xl font-light text-center uppercase transform -translate-x-1/2 opacity-0 font-montserrat text-bs-purple animate-slideInRight">
            <span>Designer</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HeadTitle;
